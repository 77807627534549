import axios from 'axios';

// const API_URL = 'http://localhost:8000/backend/actions/api.php';
const API_URL = 'https://exames-online-php-i5vm73zduq-lm.a.run.app/actions/api.php';

export const isUserLoggedIn = async () => {
    try {
        const response = await axios.get(`${API_URL}?endpoint=getUserLoggedIn`, {
            withCredentials: true, 
        });
        return response.data.loggedIn;
    } catch (error) {
        console.error('Error checking session status:', error);
        return false;
    }
};

export const login = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}?endpoint=login`, { email, password });
        return response.data;
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

export const logout = async () => {
    try {
        const response = await fetch(`${API_URL}?endpoint=logout`, {
            method: 'POST',
            credentials: 'include'
        });
        if (!response.ok) {
            throw new Error('Logout failed');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getUserEmail = async () => {
    try {
        const response = await axios.get(`${API_URL}?endpoint=getUserEmail`);
        return response.data;
    } catch (error) {
        console.error('Error getting user email:', error);
        throw error;
    }
};

export const changeUserData = async (formData) => {
    try {
        const response = await fetch(`${API_URL}?endpoint=changeUserData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
        return await response.json();
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
};

export const getNews = async () => {
    try {
        const response = await axios.get(`${API_URL}?endpoint=getNews`);
        return response;
    } catch (error) {
        console.error('Error fetching news:', error);
        throw error;
    }
};

export const getUsers = async () => {
    try {
        const response = await axios.get(`${API_URL}?endpoint=getUsers`);
        return response;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

export const getStats = async () => {
    try {
        const response = await axios.get(`${API_URL}?endpoint=getStats`);
        return response;
    } catch (error) {
        console.error('Error fetching stats:', error);
        throw error;
    }
}

export const addUser = async (formData) => {
    try {
        const response = await fetch(`${API_URL}?endpoint=addUser`, {
            method: 'POST',
            body: formData,
        });
        return await response.json();
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
};

export const writeNews = async (formData) => {
    try {
        const response = await fetch(`${API_URL}?endpoint=writeNews`, {
            method: 'POST',
            body: formData,
        });
        return await response.json();
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
};

export const changeUserRole = async (formData) => {
    try {
        const response = await fetch(`${API_URL}?endpoint=changeUserRole`, {
            method: 'POST',
            body: formData,
        });
        return await response.json();
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
}

export const fetchTable = async (tableId) => {
    try {
        const response = await axios.get(`${API_URL}?endpoint=fetchTable&tableId=${tableId}`);
        return response;
    } catch (error) {
        console.error('Error fetching table:', error);
        throw error;
    }
};

export const processInput = async (formData) => {
    try {
        const response = await fetch(`${API_URL}?endpoint=processInput`, {
            method: 'POST',
            body: formData,
        });
        return await response.json();
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
}

export const uploadExam = async (formData) => {
    try {
        const response = await fetch('https://exames-online-storage-589238062218.europe-central2.run.app/upload', {
            method: 'POST',
            body: formData,
        });
        return await response.json();
    } catch (error) {
        console.error('There was a problem with the upload:', error);
        throw error;
    }
}