import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Interface from './components/Interface';
import Admin from './components/Admin';
import Account from './components/Account';
import Login from './components/Login';
import { isUserLoggedIn } from './services/api';
import { getUserEmail } from './services/api';
import axios from 'axios';
axios.defaults.withCredentials = true;

const App = () => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [user, setUser] = useState({ email: '' , role: '' });

  useEffect(() => {
    const checkLoginStatus = async () => {
      const status = await isUserLoggedIn();
      setLoggedIn(status);
    };

    const getUserInfo = async () => {
      const response = await getUserEmail();
      setUser(response);
    };

    checkLoginStatus();
    getUserInfo();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin" element={<Admin loggedUser={user} />} />
        <Route path="/account" element={<Account />} />
        {loggedIn !== null && (
          <Route path="/" element={loggedIn ? <Interface user={user} setLoggedIn={setLoggedIn}/> : <Navigate to="/login" />} />
        )}
        <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setUser={setUser} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
