import React, { useEffect, useState } from 'react';
import { MdErrorOutline } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";

const InterfaceMessage = ({ message, type, duration = 5000, onDismiss }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onDismiss) onDismiss(); 
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onDismiss]);

  if (!visible || !message) return null;

  return (
    <div id="messages" className={`interface-${type === 'error' ? 'error' : 'success'}-message`} style={{ display: 'flex' }}>
      <div className={`inside-message-${type}`}>
        <div className="icon-title">
          {type === 'error' ? <MdErrorOutline /> : <FaRegCircleCheck />}
          <span>{type === 'error' ? 'Erro :' : 'Sucesso :'}</span>
        </div>
        <span>{message}</span>
      </div>
    </div> 
  );
};

export default InterfaceMessage;
