import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/api';

const Login = ({ setLoggedIn, setUser }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = await login(email, password);

        if (data.errors && data.errors.length > 0) {
            setErrors(data.errors);
        } else {
            setUser(prevUser => ({
                ...prevUser,
                email: data.email,
                role: data.role
            }));
            setLoggedIn(true);
            navigate('/');
        }
    };

    return (
        <section className="login-wrapper">
            <div>
                <form className="login-form" onSubmit={handleSubmit}>
                    <label htmlFor="email">Email</label>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />

                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    
                    {errors.length > 0 && (
                        <div className="error-message">
                            {errors.map((error, index) => (
                                <p key={index}>{error}</p>
                            ))}
                        </div>
                    )}

                    <input id="submit" type="submit" value="Login" />
                </form>
            </div>
        </section>
    );
}

export default Login;