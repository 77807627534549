import React from 'react';
import { BsCloudUploadFill } from "react-icons/bs";
import { PiFileTextFill } from "react-icons/pi";
import { IoIosSave } from "react-icons/io";

const InterfaceProgress = ({ message }) => {
  const parts = message.split('\n');
  const sentence = parts[0];
  const percentage = parts[1];
  console.log('Percentage:', percentage);

  return (
    <div id="messages" className="interface-progress-message" style={{ display: 'flex' }}>
      <div className="inside-message-progress">
        <div className="icon-title">
          {sentence === 'Leitura do ficheiro PDF' &&
            <BsCloudUploadFill />
          } {sentence === 'Processo do texto do exame' &&
            <PiFileTextFill />
          } { sentence === 'Guardar' &&
            <IoIosSave />
          }
          <span className="progress-bar-title">{sentence}</span>
        </div>
        <div className="progress-bar-full"></div>
        <div className="progress-bar" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );
};

export default InterfaceProgress;