import React, { useState, useEffect } from 'react';

const Message = ({ message, type, duration = 5000, onDismiss }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onDismiss) onDismiss(); 
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onDismiss]);

  if (!visible || !message) return null;

  return (
    <div className={`message-element ${type === 'error' ? 'error-message' : 'success-message'}`}>
      {message}
    </div>
  );
};

export default Message;
