import React, { useState, useEffect } from 'react';
import Message from './Message';
import { getUserEmail } from '../services/api'; 
import { changeUserData } from '../services/api';

function Account() {
  // Assume errors are passed via props or fetched from an API
  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState({ email: '' });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  useEffect(() => {
    // Placeholder function for fetching user data
    const fetchUserData = async () => {
      const userEmail = await getUserEmail(); 
      setUser({ email: userEmail });
    };

    fetchUserData();

    if (errors.length > 0) {
      Message(errors[0], 'error', document.querySelector('#submit'));
    }
  }, [errors]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      email: user.email,
      password: password,
      confirmPassword: confirmPassword
    };

    try {
        const result = await changeUserData(formData);
        if (result.errors) {
            console.error('Form submission errors:', result.errors);
            Message(result.errors[0], 'error', document.querySelector('#submit'));
        } else if (result.message) {
            console.log('Form submitted successfully:', result.message);
            Message(result.message, 'success', document.querySelector('#submit'));
        }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    user.email && (
        <section className="account-wrapper">
            <div>
                <form className="form" method="post" onSubmit={handleSubmit}>
                <h2>Minha Conta</h2>
                <h5>Para mudar a senha, insira a nova senha no campo "Password" e clique no botão "Guardar"</h5>
                
                <label>Email</label>
                <input
                    type="text"
                    name="email"
                    placeholder={user.email}
                    autoComplete="email"
                    disabled
                />

                <label>Password</label>
                <input
                    id="password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="password"
                />

                <label>Confirmar Password</label>
                <input
                    id="confirm-password"
                    type="password"
                    name="confirm-password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required={password.length > 0}
                    autoComplete="password"
                />

                <input id="submit" type="submit" value="Guardar" />
                </form>
            </div>
        </section>
    )
  );
}

export default Account;
