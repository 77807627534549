import React, { useState, useEffect, useRef } from 'react';
import { format, set } from "date-fns";
import Message from "./Message"

import { getNews } from '../services/api';
import { getUsers } from '../services/api';
import { getStats } from '../services/api';
import { addUser } from '../services/api';
import { writeNews } from '../services/api';
import { changeUserRole } from '../services/api';

import { IoNewspaper } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { FaPenFancy } from "react-icons/fa";
import { FaChartSimple } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import { BsPatchCheck } from "react-icons/bs";
import { MdDashboard } from "react-icons/md";

const Admin = ({ loggedUser }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [news, setNews] = useState([]);
  const [users, setUsers] = useState([]);
  const [stats, setStats] = useState([]);
  const [newUser, setNewUser] = useState({ email: '' , password: '', admin: false });
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, right: 0 });
  const [selectedUser, setSelectedUser] = useState(null);
  const [summary, setSummary] = useState('');
  const [comment, setComment] = useState('');

  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('');
  const [messageFromMenu, setmessageFromMenu] = useState(false);
  const slideBoardRef = useRef(null);

  const refs = useRef({});
  const parentRef = useRef(null);
  const menuRef = useRef(null);

  const fetchNews = async () => {
    try {
      const response = await getNews();
      setNews(response.data);
    } catch (error) {
      console.error('Failed to fetch news:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await getUsers();
      setUsers(response.data);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const fetchStatistics = async () => {
    try {
        const response = await getStats();
        setStats(response.data);
    } catch (error) {
        console.error('Failed to fetch statistics:', error);
    }
  };

  useEffect(() => {
    fetchNews();
    fetchUsers();
    fetchStatistics();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showCreateUser && !document.getElementById('create-user').contains(event.target)) {
        setShowCreateUser(false);
      }
    };

    if (showCreateUser) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCreateUser]);

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setShowUserMenu(false);
        }
    };

    if (showUserMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showUserMenu]);

  useEffect(() => {
    if (slideBoardRef.current) {
      slideBoardRef.current.scrollTop = slideBoardRef.current.scrollHeight;
    }
  }, [news]);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
    if (index === 1) fetchUsers();
  };

  const handleCreateUser = async () => {
    console.log(newUser);
    const formData = new FormData();
    formData.append('email', newUser.email);
    formData.append('password', newUser.password);
    formData.append('admin', newUser.admin);
    try {
      const response = await addUser(formData);
      console.log(response);
      if(response.message) {
        fetchUsers();
        setNewUser({ email: '', password: '', admin: false });
        setShowCreateUser(false);
        setMessage(response.message);
        setMessageType('success');
      } else {
        setMessage(response.error);
        setMessageType('error');
      }
    } catch (error) {
      console.error('Failed to create user:', error);
      setMessage('An error occurred while creating the user.');
      setMessageType('error');
    }
  };

  const handleUserMenuClick = (user) => {
    const ref = refs.current[user.id];
    const parentRect = parentRef.current.getBoundingClientRect();
    if (ref) {
      const rect = ref.getBoundingClientRect();
      const parentRight = parentRect.right;
      const iconRight = rect.right;
      const menuRight = parentRight - iconRight;
      setMenuPosition({ top: rect.bottom - parentRect.top, right: menuRight });
    }
    setSelectedUser(user);
    setShowUserMenu(true);
  };

  const handleMenuOptionClick = async (option) => {
    const formData = new FormData();
    formData.append('id', selectedUser.id);
    formData.append('action', option);
    if (selectedUser) {
      const response = await changeUserRole(formData);
      if (response.success === true) {
        fetchUsers();
        setmessageFromMenu(true);
        setMessage(response.message);
        setMessageType('success');
      } else {
        setmessageFromMenu(true);
        setMessage(response.message);
        setMessageType('error');
      }

      setShowUserMenu(false);
    }
  };

  const handleSubmitNews = async () => {
    const formData = new FormData();
    formData.append('summary', summary);
    formData.append('comment', comment);
    try {
      const response = await writeNews(formData);
      if(response.message) {
        fetchNews(); 
        setSummary('');
        setComment('');
        setMessage(response.message);
        setMessageType('success');
      } else {
        setMessage(response.error);
        setMessageType('error');
      }
      
    } catch (error) {
      console.error('Failed to submit news:', error);
    }
  };

    const formatedDate = (date) => {
        return format(new Date(date), "HH:mm:ss dd/LL/yyyy");
    };

    const handleMessageDismiss = () => {
        setMessage(null);
        setMessageType('');
        setmessageFromMenu(false);
    };

  return (
    news.length > 0 && (
    <section className="section section-admin-page">
      <div className="admin-container">
        <div className="admin-wrapper">
            <div className="admin-menu">
                {/* <MdDashboard /> */}
                <h3 className="admin-menu-letters">Consola de Administrador</h3>
                <div className="admin-menu-options">
                    <div className="admin-menu-child-dashboard" onClick={() => handleSlideChange(0)}>
                    <IoNewspaper />
                    <h4 className="admin-menu-title">Noticias</h4>
                    </div>
                    <div className="admin-menu-child-dashboard" onClick={() => handleSlideChange(1)}>
                    <FaUsers />
                    <h4 className="admin-menu-title">Utilizadores</h4>
                    </div>
                    { loggedUser.email === 'bernardo@gmail.com' && ( 
                    <div className="admin-menu-child-dashboard settings-menu-child" onClick={() => handleSlideChange(3)}>
                        <FaPenFancy />
                        <h4 className="admin-menu-title">Escrever Noticias</h4>
                    </div>
                    )}
                    <div className="admin-menu-child-dashboard" onClick={() => handleSlideChange(2)}>
                    <FaChartSimple />
                    <h4 className="admin-menu-title">Estatisticas</h4>
                    </div>
                </div>
            </div>

            <div className={`admin-section-container ${currentSlide === 0 ? 'active' : 'inactive'}`}>
                <div className="admin-dashboard-page">
                <h3>News</h3>
                <div className="slide-board" ref={slideBoardRef}>
                    <div id="news-container">
                    {news.length > 0 ? (
                        news.map((item, index) => (
                        <div key={index} className="news-item">
                            <p>
                              <span>bernardo </span>
                              <span>{formatedDate(item.created_at)} </span>
                              <span>{item.summary}</span><br />
                              <span>{item.comment}</span>
                            </p>
                        </div>
                        ))
                    ) : (
                        <p>Noticias indisponiveis.</p>
                    )}
                    </div>
                </div>
                </div>
            </div>
            
            {currentSlide === 1 && (
                <div ref={parentRef} className={`admin-dashboard-page ${showCreateUser ? 'change-z-index' : ''}`}>
                    {showUserMenu && (
                        <div
                        ref={menuRef}
                        id="options-menu-user"
                        className="options-menu"
                        style={{ top: `${menuPosition.top}px`, right: `${menuPosition.right}px` }}
                        >
                            <div className="option-container">
                                {selectedUser && selectedUser.role === 'admin' ? (
                                <h2 className="options-options" onClick={() => handleMenuOptionClick('demote')}>Demote user</h2>
                                ) : (
                                <h2 className="options-options" onClick={() => handleMenuOptionClick('promote')}>Promote user</h2>
                                )}
                                <h2 className="options-options" onClick={() => handleMenuOptionClick('remove')}>Remove user</h2>
                            </div>
                        </div>
                    )}
                    <h3>Utilizadores</h3>
                    <div className="inline-elements">
                        <button id="addNewUserButton" onClick={() => setShowCreateUser(true)} className="add-user">Adicionar Utilizador</button>
                        {message && messageType === 'success' && messageFromMenu && (
                            <Message
                            key={`${message}-${Date.now()}`}
                            message={message}
                            type={messageType}
                            onDismiss={handleMessageDismiss}
                            />
                        )}
                    </div>
                    {showCreateUser && (
                    <div id="create-user" className="options-menu">
                        <h3>Criar Utilizador</h3>
                        <input
                        id="create-user-email-input"
                        type="text"
                        value={newUser.email}
                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                        placeholder="Email"
                        required
                        autoFocus
                        />
                        <input
                        type="password"
                        value={newUser.password}
                        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                        placeholder="Password"
                        required
                        />
                        <div className="custom-checkbox-container">
                        <input
                            type="checkbox"
                            checked={newUser.admin}
                            onChange={() => setNewUser({ ...newUser, admin: !newUser.admin })}
                        />
                        <label>Administrador</label>
                        </div>
                        {message && messageType !== 'success' && !messageFromMenu && (
                            <Message
                            key={`${message}-${Date.now()}`}  // Ensure a unique key for each message
                            message={message}
                            type={messageType}
                            onDismiss={handleMessageDismiss}  // Clear the message when the component hides
                            />
                        )}
                        <button id="addNewUserSubmit" onClick={handleCreateUser}>Submeter</button>
                    </div>
                    )}
                    <table className="table-values" id="user-table1">
                    <thead>
                        <tr>
                        <th className="value-table email">Email</th>
                        <th className="value-table options"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {users.length > 0 ? (
                        users.map(user => (
                        <tr key={user.id} className="table-values-row users">
                            <td className="user-table-email">
                            <FaUserCircle />
                            <h5 className="admin-user-table-info">{user.email}</h5>
                            {user.role === 'admin' && <BsPatchCheck id="check" />}
                            </td>
                            <td>
                            <div
                                ref={el => refs.current[user.id] = el}
                                className="admin-user-table-info options-icon"
                                data-type="user"
                                data-id={user.id}
                                onClick={() => handleUserMenuClick(user)}
                            >
                                <HiDotsHorizontal />
                            </div>
                            </td>
                        </tr>
                        ))
                    ) : (
                        <tr><td><p>Utilizadores indisponiveis.</p></td></tr>
                    )}
                    </tbody>
                    </table>
                </div>
            )}
            
            {currentSlide === 2 && (
                <div className="admin-dashboard-page">
                    <div className="admin-section admin-section-users">
                        <h3 className="admin-section-title">Estatisticas</h3>                                                      
                    </div>
                    <div className="admin-section admin-section-transactions">
                        <div className="admin-transactions">
                            <div className="admin-transactions-box">

                                <div className="admin-categories-div">
                                    <table id="statsTable" className="table-values">
                                        <thead>
                                            <tr className='table-values-row'>
                                                <th className="value-table">Descrição</th>
                                                <th className="value-table">Estatistica</th>
                                            </tr>
                                        </thead>
                                            <tbody>
                                            <tr className='table-values-row'>
                                                <td><h5 className='admin-user-table-info stat'>Numero de Utilizadores</h5></td>
                                                <td><h5 className='admin-user-table-info stat'>{stats.users}</h5></td>
                                            </tr>
                                            <tr className='table-values-row'>
                                                <td><h5 className='admin-user-table-info stat'>Numero de Exames Endoscopia</h5></td>
                                                <td><h5 className='admin-user-table-info stat'>{stats.endoExams}</h5></td>
                                            </tr>
                                            <tr className='table-values-row'>
                                                <td><h5 className='admin-user-table-info stat'>Numero de Exames Colonoscopia</h5></td>
                                                <td><h5 className='admin-user-table-info stat'>{stats.colonExams}</h5></td>
                                            </tr>
                                            <tr className='table-values-row'>
                                                <td><h5 className='admin-user-table-info stat'>Numero de Exames Anatomia Patologica</h5></td>
                                                <td><h5 className='admin-user-table-info stat'>{stats.anatExams}</h5></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                                 
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {currentSlide === 3 && (
                <div className="admin-dashboard-page">
                    <h3 className="admin-section-title">Settings</h3>
                    <div className="slide-board">
                    <div className="write-news">
                        <h4>Write News</h4>
                        <h5>Summary</h5>
                        <input
                        type="text"
                        value={summary}
                        onChange={(e) => setSummary(e.target.value)}
                        placeholder="Main Title"
                        required
                        />
                        <h5>Comment</h5>
                        <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Comment"
                        required
                        />
                        {message && !messageFromMenu && (
                            <Message
                            key={`${message}-${Date.now()}`}
                            message={message}
                            type={messageType}
                            onDismiss={handleMessageDismiss}
                            />
                        )}
                        <button onClick={handleSubmitNews}>Submit</button>
                    </div>
                    </div>
                </div>
            )}
            

        </div>
      </div>
    </section> )
  );
};

export default Admin;
