import React, { useState, useEffect, useRef } from 'react';
import { logout } from '../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MdSimCardDownload } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import InterfaceMessage from './InterfaceMessage';
import InterfaceProgress from './InterfaceProgress';
import { fetchTable } from '../services/api';
import { processInput } from '../services/api';
import { uploadExam } from '../services/api';
import { set } from 'date-fns';

import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { upload } from '@testing-library/user-event/dist/upload';

const Interface = ( { user }, {setLoggedIn}) => {
    const [fileQueue, setFileQueue] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedCell, setSelectedCell] = useState(null);
    const [cellInfo, setCellInfo] = useState('');

    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    const [tableData, setTableData] = useState({ endo: [], colon: [], anat: [] });
    const [currentTable, setCurrentTable] = useState('endo');
    const [searchValue, setSearchValue] = useState('');
    const [isAccountMenuVisible, setIsAccountMenuVisible] = useState(false);

    const [message, setMessage] = useState(null); 
    const [messageType, setMessageType] = useState('');
    const [logMessages, setLogMessages] = useState('');

    const [progress, setProgress] = useState(null); // State to hold progress
    const navigate = useNavigate(); 

    const [logOpen, setLogOpen] = useState(false);
    const [logPosition, setLogPosition] = useState({ x: 0, y: -400 });
    const [logButtonPosition, setLogButtonPosition] = useState({ x: -35, y: 80 });

    const tbodyRef = useRef(null);

    useEffect(() => {
        setCurrentTable('endo');
        fetchTableData('endo');
        fetchTableData('colon');
        // fetchTableData('anat');
    }, []);

    useEffect(() => {
        console.log('Cell info:', cellInfo);
        if (cellInfo) {
            const cellInfoElement = document.getElementById('cellInfo');
            console.log('Cell info element:', cellInfoElement);
            if (cellInfoElement) {
                const height = cellInfoElement.offsetHeight;
                console.log('Height:', height);
                if(height >= 60) {
                    setLogButtonPosition({ x: -35, y: height + 20 });
                }
            }
        } else {
            setLogButtonPosition({ x: -35, y: 80 });
        }
    }, [cellInfo]);

    

    const handleMouseOver = (e) => {
        const cell = e.currentTarget;
        const row = cell.closest('tr');

        if (!selectedCell && selectedRow !== row) {
            row.style.backgroundColor = '#ececec';
        }
    };

    const handleMouseLeave = (e) => {
        const cell = e.currentTarget;
        const row = cell.closest('tr');

        if (!selectedCell && selectedRow !== row) {
            row.style.backgroundColor = 'white';
        }
    };

    const handleClick = (e) => {
        const cell = e.currentTarget;
        const row = cell.closest('tr');

        // Update selected row
        setSelectedRow((prevSelectedRow) => {
            if (prevSelectedRow && prevSelectedRow !== row) {
                prevSelectedRow.style.backgroundColor = 'white';
            }
            row.style.backgroundColor = '#f2f2f2';
            return row;
        });

        // Update selected cell
        setSelectedCell((prevSelectedCell) => {
            if (prevSelectedCell && prevSelectedCell !== cell) {
                prevSelectedCell.style.border = 'none';
                prevSelectedCell.style.borderRight = '1px solid #e0e0e0';
            }
            cell.style.border = '2px solid blue';
            return cell;
        });

        setContextMenuVisible(false);
        setCellInfo(cell.textContent || cell.innerText);
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        const cell = e.currentTarget;
        const row = cell.closest('tr');

        setSelectedRow((prevSelectedRow) => {
            if (prevSelectedRow && prevSelectedRow !== row) {
                prevSelectedRow.style.backgroundColor = 'white';
            }
            row.style.backgroundColor = '#f2f2f2';
            return row;
        });

        setSelectedCell((prevSelectedCell) => {
            if (prevSelectedCell && prevSelectedCell !== cell) {
                prevSelectedCell.style.border = 'none';
                prevSelectedCell.style.borderRight = '1px solid #e0e0e0';
            }
            cell.style.border = '2px solid blue';
            return cell;
        });

        setContextMenuPosition({ x: e.pageX, y: e.pageY });
        setContextMenuVisible(true);
    };

    const handleDocumentClick = (e) => {
        if (tbodyRef.current && !tbodyRef.current.contains(e.target)) {
            setSelectedRow((prevSelectedRow) => {
                if (prevSelectedRow) {
                    prevSelectedRow.style.backgroundColor = 'white';
                }
                return null;
            });

            setSelectedCell((prevSelectedCell) => {
                if (prevSelectedCell) {
                    prevSelectedCell.style.border = 'none';
                    prevSelectedCell.style.borderRight = '1px solid #e0e0e0';
                }
                return null;
            });
            
            setCellInfo('');
            setContextMenuVisible(false);
        }
    };

    // Attach document click handler
    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const handleAdminClick = () => {
        navigate('/admin');
    };

    const handleAccountClick = () => {
        navigate('/account');
    };

    const handleMouseEnterAccount = () => {
        setIsAccountMenuVisible(true);
    };

    const handleMouseLeaveAccount = () => {
        setIsAccountMenuVisible(false);
    };

    const handleLogoutClick = async () => {
        try {
            await logout();
            setLoggedIn(false);
            navigate('/');
        } catch (error) {
            console.error('Logout failed');
        }
    };
    
    const handleTableChange = (tableId) => {
        setCurrentTable(tableId);
        fetchTableData(tableId);
    };

    const fetchTableData = async (tableId) => {
        const response = await fetchTable(tableId);
        if(!response.error) {
            setTableData(prevData => ({ ...prevData, [tableId]: response.data }));
        } else {
            setMessage(response.error);
            setMessageType('error');
        }
    };

    const handleFileInputChange = (event) => {
        const files = event.target.files;
        console.log(files);
        setFileQueue(prevQueue => [...prevQueue, ...Array.from(files)]);
    };
    
    useEffect(() => {
        if (!isProcessing && fileQueue.length > 0) {
            process();
        }
    }, [fileQueue]);
    
    const process = async () => {
        const formData = new FormData();
        fileQueue.forEach(file => {
            formData.append('files', file, file.name);
        });
    
        const response = await uploadExam(formData);
        console.log(response);
    
        if (response.message) {
            setFileQueue([]); 
            processNextFile();
        }
    };

    const processNextFile = async () => {
        if (fileQueue.length === 0) {
          setIsProcessing(false);
          setInputValue('');
          return;
        }
    
        setIsProcessing(true);
        const file = fileQueue.shift();
        const formData = new FormData();
        formData.append('pdfFiles', file, file.name);
    
        const response = await processInput(formData);
        console.log(response);
        if (response.error) {
          setMessage(response.error);
          setMessageType('error');
          processNextFile();
        } else {
          pollProgressAndCompletion();
          pollLog();
        }
    };

    const pollProgressAndCompletion = () => {
        const progressInterval = setInterval(() => {
          fetch('https://exames-online-i5vm73zduq-lm.a.run.app/check_status')
            .then(response => response.json())
            .then(data => {
                console.log(data);
              if (data.status === 'in_progress') {
                setProgress(data.message);
                setMessage(null);
              } else if (data.status === 'complete') {
                clearInterval(progressInterval);
                setProgress(null);
                setMessage(data.message);
                setMessageType('success');
                fetchTableData(currentTable);
                processNextFile();
              } else if (data.status === 'error') {
                clearInterval(progressInterval);
                setProgress(null);
                setMessage(data.message);
                setMessageType('error');
                fetchTableData(currentTable);
                processNextFile();
              }
            })
            .catch(error => {
              console.error('Error fetching status:', error);
              clearInterval(progressInterval);
              setProgress(null);
              fetchTableData(currentTable);
              processNextFile();
            });
        }, 100);
      };

    const pollLog = () => {
        const logInterval = setInterval(() => {
            fetch('https://exames-online-i5vm73zduq-lm.a.run.app/check_log')
                .then(response => response.json())
                .then(data => {
                    const timestampedMessage = `${new Date().toLocaleString()} - ${data.log}`;
                setLogMessages(prevLogMessages => prevLogMessages + timestampedMessage);
                }
                )
                .catch(error => {
                    console.error('Error fetching log:', error);
                    clearInterval(logInterval);
                });
        }, 100);
    };

    const handleMessageDismiss = () => {
        setMessage(null);
        setMessageType('');
    };

    const handleCloseLog = () => {
        setLogOpen(false);
        setLogPosition({ x: 0, y: -400 });
    }

    const handleOpenLog = () => {
        setLogOpen(true);
        setLogPosition({ x: 0, y: 0 });
    }


    return (
        <section className="interface-wrapper">
            <script src="https://kit.fontawesome.com/1c20080b15.js" crossOrigin="anonymous"></script>
            <div className="interface-menu">
                <button className="interface-menu-buttons" id="addExamButton" onClick={() => document.getElementById('pdfInput').click()}>Adicionar exame</button>
                <input type="file" id="pdfInput" accept="application/pdf" multiple style={{ display: 'none' }} value={inputValue} onChange={handleFileInputChange} />
                <div className="interface-menu-buttons">
                    <button id="endoButton" onClick={() => handleTableChange('endo')} style={{backgroundColor: currentTable === 'endo' ? '#f65101' : '', color: currentTable === 'endo' ? 'white' : ''}}>Endoscopia</button>
                    <button id="colonButton" onClick={() => handleTableChange('colon')} style={{backgroundColor: currentTable === 'colon' ? '#f65101' : '', color: currentTable === 'colon' ? 'white' : ''}}>Colonoscopia</button>
                    <button id="anatButton" onClick={() => handleTableChange('anat')} style={{backgroundColor: currentTable === 'anat' ? '#f65101' : '', color: currentTable === 'anat' ? 'white' : ''}}>Anatomia Patológica</button>
                </div>
                <button className="interface-menu-buttons" id="filtersButton" style={{ display: 'none' }}>Filtros</button>
                <div className="interface-menu-buttons" style={{ display: 'none' }}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} style={{color: "#000000",}}/>
                    <input type="text" id="searchInput" />
                    <i className="fa-solid fa-arrow-right" id="searchArrow"></i>
                </div>
                <button className="interface-menu-buttons" id="downloadButton"><MdSimCardDownload /></button>
                <div id="accountWrapper" onMouseEnter={handleMouseEnterAccount} onMouseLeave={handleMouseLeaveAccount}>
                    <button className="interface-menu-buttons" id="accountButton">
                        <FaUserAlt />
                    </button>
                    {isAccountMenuVisible && (
                        <div id="accountMenu">
                            {user.role == 'admin' && (
                                <button id="adminButton" onClick={handleAdminClick}>Administração</button>
                            )}
                            <button id="definitionsButton" onClick={handleAccountClick}>Definições</button>
                            <button id="logoutButton" onClick={handleLogoutClick}>Logout</button>
                        </div>
                    )}
                </div>
            </div>
            
            {contextMenuVisible && (
            <div id="optionMenu" style={{top: contextMenuPosition.y, left: contextMenuPosition.x}}>
                <button id="removeOption" data-option="remove">Remover Exame</button>
                <button id="reportOption" data-option="report">Reportar Erro</button>
            </div> )}

            <div className="interface-content">
                {currentTable === 'endo' && (
                    <div id="endoTable">
                        <h3>Endoscopia</h3>
                        <table id="tableContainer-endo" className="table-values interface">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Episódio</th>
                                    <th>Data</th>
                                    <th>Utente</th>
                                    <th>Sexo</th>
                                    <th>Idade</th>
                                    <th>Motivo</th>
                                    <th>Esofago</th>
                                    <th>Estomago</th>
                                    <th>Duodeno</th>
                                    <th>Procedimentos</th>
                                    <th>Resultado</th>
                                    <th>Segmento</th>
                                    <th>Limitação</th>
                                    <th>Tolerância</th>
                                    <th>Conclusões</th>
                                    <th>Executante</th>
                                    <th>Anestesista</th>
                                    <th>Enfermeiro</th>
                                </tr>
                            </thead>
                            <tbody ref={tbodyRef}>
                            {tableData.endo.length > 0 ? (
                                tableData.endo.map((exam, index) => (
                                    <tr key={index}
                                     className='table-values-row' 
                                     data-exam-id={exam.id}
                                        >
                                        <td
                                        onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.id}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.episodio}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.dia}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.utente}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.sexo}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.idade}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row' dangerouslySetInnerHTML={{ __html: exam.motivos.join('<br> ') }}></h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row' dangerouslySetInnerHTML={{ __html: exam.esofagos.join('<br> ') }}></h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row' dangerouslySetInnerHTML={{ __html: exam.estomagos.join('<br> ') }}></h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row' dangerouslySetInnerHTML={{ __html: exam.duodenos.join('<br> ') }}></h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.procedimentos}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.resultado}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.segmento}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.limitacao}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.tolerancia}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row' dangerouslySetInnerHTML={{ __html: exam.conclusoes.join('<br> ') }}></h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.executante}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.anestesista}</h5></td>
                                        <td
                                        onMouseOver={handleMouseOver}
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleClick}
                                            onContextMenu={handleContextMenu}><h5 className='exam-row last-interface'>{exam.enfermeiro}</h5></td>     
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="19">A carregar ficheiros...</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}

                {currentTable === 'colon' && (
                    <div id="colonTable">
                        <h3>Colonoscopia</h3>
                        <table id="tableContainer-colon" className="table-values interface">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Episódio</th>
                                    <th>Data</th>
                                    <th>Utente</th>
                                    <th>Sexo</th>
                                    <th>Idade</th>
                                    <th>Motivo</th>
                                    <th>Perineo</th>
                                    <th>Toque</th>
                                    <th>Relatorio</th>
                                    <th>Recomendações</th>
                                    <th>Resultado</th>
                                    <th>Segmento Pretendido</th>
                                    <th>Segmento Atingido</th>
                                    <th>Limitação</th>
                                    <th>Tolerância</th>
                                    <th>Conclusões</th>
                                    <th>Executante</th>
                                    <th>Anestesista</th>
                                    <th>Enfermeiro</th>
                                </tr>
                            </thead>
                            <tbody ref={tbodyRef}>
                            {tableData.colon.map((exam, index) => (
                                <tr key={index} className='table-values-row' data-exam-id={exam.id}>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.id}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.episodio}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.dia}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.utente}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.sexo}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.idade}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row' dangerouslySetInnerHTML={{ __html: exam.motivos.join('<br>') }}></h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.perineo}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.toque}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row' dangerouslySetInnerHTML={{ __html: exam.relatorios.join('<br>') }}></h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.recomendacoes}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.resultado}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.segPretendido}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.segAtingido}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.limitacao}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.tolerancia}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row' dangerouslySetInnerHTML={{ __html: exam.conclusoes.join('<br>') }}></h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.executante}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row'>{exam.anestesista}</h5></td>
                                    <td
                                    onMouseOver={handleMouseOver}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={handleClick}
                                        onContextMenu={handleContextMenu}><h5 className='exam-row last-interface'>{exam.enfermeiro}</h5></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {currentTable === 'anat' && (
                    <div id="anatTable">
                        <h3>Anatomia Patológica</h3>
                        <table className="table-values interface">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Episódio</th>
                                    <th>Data</th>
                                    <th>Exame</th>
                                    <th>Utente</th>
                                    <th>Natureza</th>
                                    <th>Informação</th>
                                    <th>Macroscopia F1</th>
                                    <th>Macroscopia F2</th>
                                    <th>Microscopia</th>
                                    <th>Microscopia F1</th>
                                    <th>Microscopia F2</th>
                                    <th>Microscopia F3</th>
                                    <th>Conclusão</th>
                                </tr>
                            </thead>
                            <tbody ref={tbodyRef}>
                            {/*tableData.anat.map((exam, index) => (
                                <tr key={index} className='table-values-row'>
                                    <td>{index + 1}</td>
                                    <td><h5 className='admin-user-table-info'>{exam.episodio}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.dia}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.exame}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.id_user}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.natureza}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.informacao}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.MacroF1}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.MacroF2}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.Micro}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.MicroF1}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.MicroF2}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.MicroF3}</h5></td>
                                    <td><h5 className='admin-user-table-info'>{exam.Conclusao}</h5></td>
                                </tr>
                            ))*/}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            {progress && <InterfaceProgress key={`${message}-${Date.now()}`} message={progress} />}
            
            {message && !progress && (
                <InterfaceMessage 
                    key={`${message}-${Date.now()}`}
                    message={message} 
                    type={messageType}
                    onDismiss={handleMessageDismiss} />
             )}
            {cellInfo && (
                <div id="cellInfo">
                    <h5>{cellInfo}</h5>
                </div>
            )}

            <aside className="log-box" style={{ left: `${logPosition.y}px`, top: `${logPosition.x}px` }}>
                {logOpen && (<button className="logButton" onClick={handleCloseLog}><IoIosArrowBack /></button>)}
                {!logOpen && (<button className="logButton" onClick={handleOpenLog} style={{ bottom: `${logButtonPosition.y}px`, right: `${logButtonPosition.x}px` }}><IoIosArrowForward /></button>)}
                <div id="log" style={{ whiteSpace: 'pre-wrap' }}>
                    {logMessages}
                </div>
            </aside>
            
        </section>
    );
};

export default Interface;